<template>
  <v-row>
    <v-col sm="12" md="12">
      <v-card>
        <v-card-title>
          <v-icon class="mr-2">mdi-hair-dryer-outline</v-icon>
          Beauty Spots
          <v-spacer></v-spacer>
          <v-text-field
            v-model="paginationOptions.search"
            class="mr-3"
            append-icon="mdi-magnify"
            label="Buscar"
            placeholder="Informe o Nome Fantasia ou Razão Social"
            single-line
            hide-details
            @keydown="buscar()"
            @click:append="buscar()"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-switch
            v-model="beautySpots.active"
            :label=" `Exibindo ${beautySpots.active? 'Ativos': 'Inativos'}`"
            single-line
            hide-details
            class="mr-3"
            style="width: 250px"
            @change="buscar()"
          ></v-switch>
          <v-btn
            :to="{ name: 'bscreate' }"
            color="#3D1159"
            dark
            title="Criar Beauty Spot"
          >
            <v-icon class="mr-1">mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </v-card-title>

        <v-row>
          <v-col md="12">
            <v-alert
              dense
              outlined
              type="warning"
              v-if="!beautySpotPagination && !beautySpots"
            >
              Beauty Spots não encontrados
            </v-alert>
          </v-col>
        </v-row>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="filteredBeautySpotsItems()"
          :options.sync="options"
          :server-items-length="beautySpots.totalItems"
          :search.sync="paginationOptions.search"
          item-key="id"
          class="elevation-1"
          v-show="beautySpots.items"
          :footer-props="{
                        'items-per-page-options': [10, 25, 50, 75, 100]
                      }"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#3D1159"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                    name: 'beautyspot',
                    params: { id: item.id }
                  }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {BEAUTY_SPOT_SEARCH} from "@/store/actions/beautyspots.type";
import {
  BEAUTY_SPOTS_RESET, SET_BEAUTY_SPOT_PAGINATION,

} from "@/store/mutations/beautyspots.type";

export default {
  name: "Beauty-spot",
  props: {
    source: String
  },

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(BEAUTY_SPOTS_RESET),
      store.dispatch(BEAUTY_SPOT_SEARCH)
    ]).then(() => {
      next();
    });
  },

  async beforeDestroy() {
      if(!this.$route.path.includes('/beautyspot')){
          localStorage.removeItem('beautySpotsOptions')
          localStorage.removeItem('beautySpotPaginationOptions')
      }
  },

  data: () => ({
    headers: [
      {text: "Razão Social", value: "companyName", align: "left"},
      {text: "Nome Fantasia", value: "fantasyName", align: "left"},
      {text: "CNPJ", value: "cnpj"},
      // {text: "Categoria", value: "categories", align: "left"},
      {text: "Visualizar", value: "actions", align: "center", sortable: false}
    ],
    selected: null,
    options: {},
    paginationOptions: {},
    //rules
    textRules: [
      // v => !!v || "Este campo é obrigatório",
      v =>
        (v && v <= 900) || "O valor deste campo não pode exceder mais que 900"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ]
  }),

  methods: {

    buscar() {
      const {sortBy, sortDesc, itemsPerPage, page} = this.options
      this.beautySpotPagination.search = this.paginationOptions.search
      store.commit(SET_BEAUTY_SPOT_PAGINATION, {
        page: page,
        itemsPerPage: itemsPerPage,
        orderBy: sortBy,
        sortDesc: sortDesc[0]
      })
      store.dispatch(BEAUTY_SPOT_SEARCH);
    },

    filteredBeautySpotsItems() { 
        const items = this.beautySpots.items
        if(this.beautySpotPagination.search){
            const search = this.beautySpotPagination.search.toLowerCase()
            this.options.itemsPerPage = this.beautySpots.totalItems
            this.options.page = 1
            const exactMatches = [];
            const partialMatches = [];

            items.forEach(item => {
                const itemValues = Object.values(item).map(value => String(value).toLowerCase());
                const isExactMatch = itemValues.some(value => value.startsWith(search));
                const isPartialMatch = itemValues.some(value => value.includes(search));
                
                if (isExactMatch) {
                    exactMatches.push(item);
                } else if (isPartialMatch) {
                    partialMatches.push(item);
                }
            });

            return [...exactMatches,...partialMatches];
        }
        else {
            if(this.options.itemsPerPage == this.beautySpots.totalItems){
                this.options.itemsPerPage = 10
            }
            return items;
        } 
    }

  },
  mounted() {
      if (localStorage.getItem('beautySpotsOptions')){
          this.options = JSON.parse(localStorage.getItem('beautySpotsOptions'))
      }
      if (localStorage.getItem('beautySpotPaginationOptions')){
          this.paginationOptions = JSON.parse(localStorage.getItem('beautySpotPaginationOptions'))
      }
  },
  watch: {
    options: {
      handler(newOptions) {
        this.buscar()
        const parsed = JSON.stringify(newOptions)
        localStorage.setItem('beautySpotsOptions', parsed)
      },
      deep: true,
    },
    paginationOptions: {
        handler(newSearch) {
            this.buscar()
            const parsed = JSON.stringify(newSearch)
            localStorage.setItem('beautySpotPaginationOptions', parsed)
        },
        deep: true,
    }
  },
  computed: {
    ...mapGetters(["beautySpots", "checkUsers", "beautySpotPagination"])
  }
};
</script>
